.asset{

    border: 1px solid #ebebeb;

    cursor: pointer;
    
    transition: border .2s ease-out;

    overflow: hidden;

    background-color: #fff;

    >div.bg-contain{

        transition: transform .2s ease-out;

        transform: scale( 1 );
    }

    &:hover{

        border: 1px solid #333333;

        >div.bg-contain{

            transform: scale( 1.05 );
        }
    }
}

.asset-grid{

    gap: 10px;

    display: flex;

    flex-wrap: wrap;

    .asset{

        flex: 0 0 calc( 25% - ( ( 10px * 3 ) / 4 ) );

        @media( max-width: $tablet-max-width ){

            flex: 0 0 calc( 50% - ( ( 14px ) / 2 ) );
        }
    
        @media( max-width: $tablet-min-width ){
    
            flex: 0 0 calc( 50% - ( ( 14px ) / 2 ) );
        }
    
        @media( max-width: $mobile-min-width ){
    
            flex: 0 0 100%;
        }
    }
}
